import React from "react"
import { ThemeProvider } from "emotion-theming"
import { Global, css } from "@emotion/core"

import theme from "../theme"
import { Flex } from "rebass"
import Navbar from "./Navbar"
import Footer from "./Footer"

export default function Layout({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <Global
        styles={css`
          body {
            margin: 0;
            background: ${theme.colors.primary};
          }
          @font-face {
            font-family: "regular";
            font-weight: 400;
            src: url("fonts/Regular-Bold.otf");
          }

          @font-face {
            font-family: "regular";
            font-weight: 700;
            src: url("fonts/Regular-Extrabold 3.otf");
          }
          @font-face {
            font-family: "regular";
            font-weight: 900;
            src: url("fonts/Regular-Black.otf");
          }
        `}
      />
      <Flex bg="primary" flexDirection="column">
        <Navbar />
        {children}
        <Footer />
      </Flex>
    </ThemeProvider>
  )
}
