import React from "react"
import { Flex, Box, Heading, Button, Text, Link } from "rebass"

export default function Footer() {
  return (
    <Flex bg="secondaryBackground" flexDirection="column" py={6} px={[4, 6]}>
      <Box mb={6}>
        <Text variant="primaryText" mb={1}>
          London
        </Text>
        {/* <Text variant="primaryText" mb={1}>
          2 Frank Street
        </Text>
        <Text variant="primaryText" mb={1}>
          London EC2Y 8NA{" "}
        </Text> */}
        <Text variant="primaryText" mb={1}>
          info@plank.ai
        </Text>
        <Text variant="primaryText" mb={1}>
          +44 (0)7921 085400
        </Text>
      </Box>
      <Flex flexDirection={["column", "row"]} fontSize={1} mb={4}>
        <Text variant="primaryText" mr={[0, 3]} mb={[1, 0]}>
          © 2020 Plank Ltd
        </Text>
        {/* <Link variant="footerLink" mx={[0, 3]} my={[1, 0]}>
          Privacy
        </Link>
        <Link variant="footerLink" ml={[0, 3]} mt={[1, 0]}>
          Terms of Use
        </Link> */}
      </Flex>
      <Text variant="primaryText" fontSize="9px">
        A private limited company incorporated in England and Wales under
        company number 10063271.{" "}
      </Text>
    </Flex>
  )
}
