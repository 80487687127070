import React, { useState } from "react"
import { Flex, Heading, Button, Box } from "rebass"
import Link from "./Link"
import NavbarDropDown from "./NavbarDropdown"
import useWindowPosition from "../hooks/useWindowPosition"
import SkinnyNavbar from "../components/SkinnyNavbar"

const BurgerIcon = props => (
  <svg
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 384 384"
  >
    <path d="M0 277.333h384V320H0zM0 170.667h384v42.667H0zM0 64h384v42.667H0z" />
  </svg>
)

const CloseIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={props.fill}
    height="24"
    viewBox="0 0 386.667 386.667"
  >
    <path d="M386.667 45.564L341.103 0l-147.77 147.769L45.564 0 0 45.564l147.769 147.769L0 341.103l45.564 45.564 147.769-147.769 147.769 147.769 45.564-45.564-147.768-147.77z" />
  </svg>
)

const Logo = () => (
  <svg
    height="64px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50.21 71.53"
  >
    <g data-name="Layer 2">
      <path
        d="M42.31 7.9A23.39 23.39 0 0025.64 1h-4.72a6.61 6.61 0 00-6.62 6.6.43.43 0 000 .05h0v6.56h11.34a10.36 10.36 0 010 20.71 6.61 6.61 0 000 13.22A23.57 23.57 0 0042.31 7.9zm-28.13 6.4H7.6A6.61 6.61 0 001 20.91v43a6.61 6.61 0 1013.22 0V14.28z"
        fill="none"
        stroke="#4C55FF"
        stroke-width="1.98"
        data-name="Layer 1"
      />
    </g>
  </svg>
)

export default function Navbar() {
  const [open, setOpen] = useState(false)
  const y = useWindowPosition()

  function jumpto(anchor) {
    window.location.href = "#" + anchor
  }

  return (
    <Flex
      flexDirection="column"
      sx={{
        width: "100%",
        zIndex: "1000",
        backgroundColor: "primary",
      }}
    >
      <Flex
        px={[4, 5]}
        py={5}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <a href="/" style={{ textDecoration: "none" }}>
            <Logo />
          </a>
        </Box>

        <Box mx="auto" />
        <Flex alignItems="center" id="navBar">
          <a href="/#AboutUs" style={{ textDecoration: "none" }}>
            <Link variant="nav" id="navLink" to="/#AboutUs">
              About Us
            </Link>
          </a>
          <a href="/#Services" style={{ textDecoration: "none" }}>
            <Link variant="nav" id="navLink">
              Services
            </Link>
          </a>
          {/* <Link variant="nav" id="navLink">
            Case Studies
          </Link> */}
          <a href="/contact" style={{ textDecoration: "none" }}>
            <Button
              variant="primaryNav"
              display={["none", "flex"]}
              ml={3}
              style={{
                height: "40px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Contact
            </Button>
          </a>
        </Flex>
        {/* <SkinnyNavbar style={{ display: y > 250 ? null : "inline" }} /> */}
        <Flex>
          <Button variant="burger" onClick={() => setOpen(!open)}>
            {open ? (
              <CloseIcon fill="#4C55FF" />
            ) : (
              <BurgerIcon fill="#4C55FF" />
            )}
          </Button>
        </Flex>
      </Flex>
      <Box display={!open && "none"}>
        <NavbarDropDown />
      </Box>
    </Flex>
  )
}
