import React, { useState } from "react"
import { Flex } from "rebass"
import Link from "./Link"

export default function NavbarDropDown() {
  const [open, setOpen] = useState(false)

  return (
    <Flex
      px={4}
      pb={8}
      flexDirection="column"
      textAlign="center"
      alignItems="center"
      backgroundColor="primary"
      sx={{
        zIndex: "-1",
        width: "100%",
      }}
    >
      <a
        href="/#AboutUs"
        onClick={() => setOpen(false)}
        style={{ textDecoration: "none" }}
      >
        <Link py={3} variant="navDropdown">
          About Us
        </Link>
      </a>
      <a
        href="/#Services"
        onClick={() => setOpen(false)}
        style={{ textDecoration: "none" }}
      >
        <Link py={3} variant="navDropdown">
          Services
        </Link>
      </a>
      {/* <Link onClick={() => setOpen(false)} py={4} variant="navDropdown">
        Case Studies
      </Link> */}
      <a
        href="/contact"
        onClick={() => setOpen(false)}
        style={{ textDecoration: "none" }}
      >
        <Link py={3} variant="navDropdown">
          Contact
        </Link>
      </a>
    </Flex>
  )
}
