export default {
  breakpoints: ["40em", "52em", "64em"],
  colors: {
    primary: "#0B1330",
    secondary: "#4C55FF",
    secondaryDark: "#1B25DA",
    mainBackground: "#F1F5F8",
    secondaryBackground: "#DBE2E7",
    black: "#000e1a",
    white: "#fff",
    blue: "#007ce0",
    navy: "#004175",
  },
  fontSizes: [10, 12, 14, 16, 20, 24, 32, 40, 48, 64],
  space: [0, 4, 8, 16, 24, 32, 48, 64, 128, 164, 256, 512, 768],
  fonts: {
    navigation: "regular, system-ui, sans-serif",
    body: "regular, system-ui, sans-serif",
    heading: "regular, system-ui, sans-serif",
    link: "mark, system-ui, sans-serif",
  },
  fontWeights: {
    body: 400,
    heading: 700,
    headingSecondary: 200,
    bold: 700,
    black: 900,
  },
  lineHeights: {
    body: 1.25,
    heading: 1,
    large: 1.8,
  },
  buttons: {
    primary: {
      color: "white",
      bg: "secondary",
      fontWeight: "bold",
      borderRadius: 24,
      paddingTop: "9px",
      paddingX: 5,
      fontSize: 0,
      textTransform: "uppercase",
      cursor: "pointer",
      ":hover": {
        bg: "secondaryDark",
      },
      ":focus": {
        outline: "none",
      },
    },
    primaryNav: {
      color: "white",
      bg: "secondary",
      fontFamily: "heading",
      fontWeight: "bold",
      fontSize: 2,
      borderRadius: 24,
      paddingTop: "9px",
      paddingX: 5,
      textTransform: "uppercase",
      cursor: "pointer",
      ":hover": {
        bg: "secondaryDark",
      },
      ":focus": {
        outline: "none",
      },
    },
    contact: {
      color: "white",
      bg: "secondary",
      fontWeight: "bold",
      borderRadius: 24,
      paddingY: 3,
      paddingX: 5,
      fontSize: 2,
      textTransform: "uppercase",
      ":focus": {
        outline: "none",
      },
    },
    burger: {
      backgroundColor: "transparent",
      padding: "0",
      display: ["block", "none"],
      ":focus": {
        outline: "none",
      },
    },
  },
  text: {
    largeHeading: {
      color: "primary",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
    },
    boldContent: {
      color: "primary",
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
    servicesHeading: {
      color: "secondary",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "black",
      textTransform: "uppercase",
      letterSpacing: "0.3em",
    },
    caseStudyHeading: {
      color: "secondary",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "bold",
    },
    caseStudyText: {
      color: "white",
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
    primaryText: {
      color: "primary",
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
    subHeading: {
      color: "secondary",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "bold",
      textTransform: "uppercase",
      letterSpacing: "0.1em",
    },
  },
  variants: {
    section: {
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    iconSection: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 6,
      paddingBottom: 5,
      paddingLeft: 3,
      paddingRight: 3,
    },
    nav: {
      display: ["none", "flex"],
      px: 3,
      fontFamily: "heading",
      fontWeight: "bold",
      color: "secondary",
      fontSize: 2,
      cursor: "pointer",
      textDecoration: "none",
      ":hover,:focus,.active": {
        color: "white",
      },
    },
    navActive: {
      display: ["none", "flex"],
      px: 3,
      fontFamily: "heading",
      fontWeight: "bold",
      color: "mainBackground",
      fontSize: 2,
      cursor: "pointer",
      textDecoration: "none",
      ":hover,:focus,.active": {
        color: "white",
      },
    },
    navDropdown: {
      display: "inline-block",
      width: "100%",
      fontFamily: "body",
      // fontWeight: "black",
      color: "secondary",
      fontSize: 3,
      letterSpacing: "0.05em",
      textTransform: "uppercase",
      textDecoration: "none",
      ":hover,:focus,.active": {
        color: "white",
      },
    },
    footerLink: {
      color: "primary",
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
  },
  forms: {
    input: {
      color: "secondary",
      fontFamily: "body",
      borderRadius: 4,
      borderColor: "secondary",
      marginBottom: "0.375rem",
      ":hover,:focus,.active": {
        borderColor: "mainBackground",
      },
    },
    select: {},
    textarea: {
      height: "10rem",
      color: "secondary",
      fontFamily: "body",
      borderRadius: 4,
      borderColor: "secondary",
      marginBottom: "0.375rem",
      ":hover,:focus,.active": {
        borderColor: "mainBackground",
      },
    },
    label: {
      fontSize: 12,
      color: "secondary",
      textTransform: "uppercase",
      fontFamily: "body",
      marginBottom: "0.125rem",
    },
    radio: {},
    checkbox: {},
  },
}
